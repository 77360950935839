import React, { useEffect, useRef, useState } from 'react';
import '../../style/players/videoPlayer.css';

const VideoPlayer = ({ currentShowcase }) => {
  const videoRef = useRef(null);
  const [showPlayButton, setShowPlayButton] = useState(true); // State to control play button visibility

  // Reload the video when switching showcases and show the play button
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load(); // Reload the video
      setShowPlayButton(true); // Show the play button when the showcase changes
    }
  }, [currentShowcase]);

  // Handle play when clicking on the video or the play button
  const handlePlayButtonClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        // Try to ensure user interaction triggers play
        videoRef.current.play().then(() => {
          setShowPlayButton(false);
          videoRef.current.controls = true;
        }).catch(error => {
          console.error('Error trying to play the video:', error);
        });
      } else {
        setTimeout(() => {
          videoRef.current.pause(); // Pause if it's already playing
          videoRef.current.controls = false;
          setShowPlayButton(true);
      }, 50); // Slight delay to trigger transition

      }
    }
  };

  return (
    <div className="showcase-video-container" style={{ position: 'relative' }}>
      {showPlayButton && (
        <div
          className="play-button-overlay"
          onClick={handlePlayButtonClick} // Play the video when play button is clicked
        >
          <span className="play-button">&#9658;</span> {/* Play icon (triangle) */}
        </div>
      )}

      <video
        id="video-player"
        ref={videoRef} // Reference to the video element
        preload="auto"
        poster={currentShowcase.thumbPath} // Show poster image
        controls={true} // Always show controls to help with user interaction
        playsInline // Important for mobile browsers, especially Safari on iPhone
        onClick={handlePlayButtonClick} // Play the video when video itself is clicked
      >
        <source
          src={`${currentShowcase.videoPath}`} // Path to the video
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
