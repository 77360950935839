import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ShowcaseArea from '../components/ShowcaseArea';
import ThumbnailSlider from '../components/ThumbnailSlider';
import '../style/showcase.css';

const Showcase = ({ showcases = [] }) => {
    const { category } = useParams(); // Get the category from the URL
    const [currentShowcase, setCurrentShowcase] = useState();
    const [subShows, setSubShows] = useState([]);

    // Create refs for the wrapper and slider
    const wrapperRef = useRef(null);
    const sliderRef = useRef(null);

    const loadShowcase = (showcase) => {
        setCurrentShowcase(showcase);
    };

    // Filter and sort showcases when showcases or category changes
    useEffect(() => {
        if (sliderRef.current && wrapperRef.current) {
            // Reset initial state
            sliderRef.current.style.opacity = '0';
            wrapperRef.current.style.bottom = '-130px';
        }

        const formattedCategory = category ? category.charAt(0).toUpperCase() + category.slice(1) : 'All';

        const filteredShowcases = showcases.filter((showcase) => 
            showcase.visible && showcase.categories.some(showcaseCategory => showcaseCategory === formattedCategory)
        );

        const sortedShowcases = filteredShowcases.sort((a, b) => b.year - a.year);

        // Wait for slide-out
        setTimeout(() => {
            setSubShows(sortedShowcases);
        }, 500); // Slight delay to trigger transition
    }, [showcases, category]);

    // Update the currentShowcase whenever subShows changes
    useEffect(() => {
        if (subShows.length > 0) {
            setCurrentShowcase(subShows[0]);
        }
    }, [subShows]);

    return (
        <div className="showcase">
            <ShowcaseArea showcase={currentShowcase} />
            <ThumbnailSlider
                showcases={subShows}
                loadShowcase={loadShowcase}
                currentShowcase={currentShowcase}
                sliderRef={sliderRef}  // Pass ref to ThumbnailSlider for direct DOM manipulation
                wrapperRef={wrapperRef} // Pass ref to ThumbnailSlider for direct DOM manipulation
            />
        </div>
    );
};

export default Showcase;
