import React, { useEffect } from 'react';
import '../style/ThumbnailSlider.css';

const ThumbnailSlider = ({ showcases = [], loadShowcase, currentShowcase, sliderRef, wrapperRef }) => {
    useEffect(() => {
        if (sliderRef.current && wrapperRef.current) {
            setTimeout(() => {
                // Manipulate DOM elements directly using refs
                sliderRef.current.style.opacity = '1';
                wrapperRef.current.style.bottom = '0';
            }, 500); // Slight delay to trigger transition
        }
    }, [showcases, currentShowcase, sliderRef, wrapperRef]);

    return (
        <div className="thumbnail-slider-wrapper" ref={wrapperRef}>
            <div className="thumbnail-slider" ref={sliderRef}>
                {showcases.map(showcase => (
                    <div key={showcase.id} className="thumbnail-item">
                        <div className="thumb-title">{showcase.videoTitle}</div>
                        <img
                            src={showcase.thumbPath ? showcase.thumbPath : ''}
                            onClick={() => loadShowcase(showcase)}
                            className={`thumbnail-image ${currentShowcase?.id === showcase.id ? 'selected' : ''}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ThumbnailSlider;
