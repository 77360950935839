import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../style/main.css';

const Main = () => {
    const [isShowingMoreTrailBlazer, setIsShowingMoreTrailBlazer] = useState(false);
    const [isShowingMorePioneering, setIsShowingMorePioneering] = useState(false);
    const [isShowingMoreMusic, setIsShowingMoreMusic] = useState(false);
    const [isShowingMoreEducation, setIsShowingMoreEducation] = useState(false);

    // Array of image paths
    const images = [
        './bgs/alps.jpg',
        './bgs/amoungstShrubs.jpg',
        './bgs/artgallery4.jpg',
        './bgs/redwoordForest.jpg',
        './bgs/graysuitpark.jpg',
        './bgs/italyCrow.jpg',
        './bgs/logowalk.jpg',
        './bgs/museumwalkway.jpg',
        './bgs/redwoordForest.jpg',
        './bgs/sanddunes.jpeg',
        './bgs/tigerLily.jpg',
        './bgs/amoungstShrubs.jpg',
        './bgs/sanddunes.jpeg',
        './bgs/wiley_beach.jpg'
    ];

    // State to store the randomly selected image and image loading state
    const [backgroundImage, setBackgroundImage] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    // Function to randomly select an image
    useEffect(() => {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        console.log(randomImage)
        setBackgroundImage(randomImage);
    }, []);

    // Handle image loading
    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    const toggleTrailBlazing = () => {
        setIsShowingMoreTrailBlazer(!isShowingMoreTrailBlazer);
    };

    const togglePioneering = () => {
        setIsShowingMorePioneering(!isShowingMorePioneering);
    };

    const toggleMusic = () => {
        setIsShowingMoreMusic(!isShowingMoreMusic);
    };

    const toggleEducation = () => {
        setIsShowingMoreEducation(!isShowingMoreEducation);
    };

    return (
        <div className='main'>
            <div
                className="image-container"
                style={{
                    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right top',
                    backgroundRepeat: 'no-repeat',
                    opacity: isImageLoaded ? 1 : 0,
                    transition: 'opacity 1.5s ease-in-out'
                }}
            >
                <div className="tooltip-container">
                    <span className="tooltip-text">All the clothes I am wearing are designed and made by me. 
                        <Link to='/showcase/nicepants'>NicePants</Link>
                    </span>
                </div>
                <img
                    src={backgroundImage}
                    alt="Background Preload"
                    style={{ display: 'none' }}
                    onLoad={handleImageLoad}
                />
            </div>
            <div className="text-overlay">
                    <p><span>Wiley Evans</span> | Seasoned software engineer and innovative musician.</p>
                    <p className='hero-text'>
                        <Link to="/showcase/software">
                            Explore my work in software development
                        </Link>
                        &nbsp;at top-tier companies, or listen to&nbsp;
                        <Link to="/showcase/composition">
                            unique compositions
                        </Link>
                        &nbsp;showcasing my&nbsp;
                        <Link to="/showcase/performance">
                            tuba playing
                        </Link>
                        &nbsp;and pushes the boundaries of sound and technology.
                    </p>
                </div>

            <div className="container">
                <section className="leadership-section">
                    <h1>Leadership in Media and Streaming Technology</h1>
                    <p>
                        I have been a part of esteemed organizations such as Lucasfilm Ltd., 3D Robotics, Elsevier, and now currently at Disney+.
                        At each of these companies, I played critical roles in projects that spanned from content discovery tools to advanced software solutions for media distribution and management. I was also one of the founding engineers at In.Live, a live performance streaming service, where I developed crucial infrastructure to support live events.
                    </p>
                </section>
                <section className="details-section">
                    <div>
                        <h1 className='first-headline'>Trailblazer in Digital Music</h1>
                        <p>
                            I was at the forefront of the MP3 revolution,
                            contributing my software engineering skills at trailblazing
                            companies like Artist Direct and Sonique.&nbsp;
                            {isShowingMoreTrailBlazer && (<span>
                                These roles underscored the pivotal contributions to the development
                                of web-based MP3 players, setting benchmarks in the burgeoning field
                                of digital music distribution. This period not only highlighted my
                                technical skills but also underscored my ability to innovate and adapt
                                to new technological frontiers.&nbsp;
                            </span>)}
                            <button onClick={toggleTrailBlazing}>
                                {isShowingMoreTrailBlazer ? 'less' : 'more'}
                            </button>
                        </p>
                    </div>
                    <div>
                        <h1>Pioneering in the Gaming Industry</h1>
                        <p>
                            My career kickstarted in the video game industry, where I designed audio
                            solutions for iconic companies such as Atari and Sega,&nbsp;
                            {isShowingMorePioneering && (<span>
                                leveraging an acute sense of sound and technical acuity. Transistioning into
                                visual software engineering allowed me to expand my technical expertise at
                                George Coates Performance Works, which focused on artistic multimedia projects,
                                contrasting with my more corporate software work at companies like Atari and
                                Disney. This shift further broadened my creative approach, integrating art
                                with technical innovation.&nbsp;
                            </span>)}
                            <button onClick={togglePioneering}>
                                {isShowingMorePioneering ? 'less' : 'more'}
                            </button>
                        </p>
                    </div>
                    <div>
                        <h1>Musical Mastery and Bridging Technology and Art</h1>
                        <p>
                            My extensive musical career adds a distinctive layer of creativity to my technical expertise.&nbsp;
                            {isShowingMoreMusic && (<span>
                                As both a performer and composer for the Extra Action Marching Band, I brought a fusion
                                of Balkan brass, New Orleans funk, and Moroccan trance to life, leading large-scale
                                performances across the East Coast and Europe. My composition skills were further
                                developed under the mentorship of renowned composers Morton Subotnick, Lucky Mosko,
                                and Mel Powell at CalArts. My work as a recording engineer at Hit Studios further
                                highlights my versatility, showcasing technical skills in sound engineering and
                                spatial audio—valuable in multimedia software and audio tech environments.
                                Additionally, my mastery of the tuba, honed under Zachariah Spellman and Roger Bobo,
                                reinforces my attention to detail and interdisciplinary approach, making me a creative
                                force that complements my technical achievements in software development. By blending 
                                my technical proficiency with artistic creativity, I consistently push boundaries in 
                                both domains, creating unique and impactful work that bridges technology and art.&nbsp;
                            </span>)}
                            <button onClick={toggleMusic}>
                                {isShowingMoreMusic ? 'less' : 'more'}
                            </button>
                        </p>
                    </div>
                    <div>
                        <h1>Education and Early Beginnings</h1>
                        <p>
                            My educational background forms the foundation for my diverse skill set, combining technical 
                            expertise with deep artistic training.&nbsp;
                            {isShowingMoreEducation && (<span>
                                I earned a Bachelor’s degree in Music Performance from San Francisco State University, 
                                where I focused on developing my tuba performance skills under the mentorship of Zachariah 
                                Spellman (San Francisco Opera, Marin Symphony), Barton Cummings(Ringling Bros. Circus Orchestra), and 
                                Roger Bobo. This training equipped me with a strong foundation in both classical performance 
                                techniques and ensemble work, which would later inform my unique approach to large-scale, 
                                collaborative musical projects.
                                <br/><br/>
                                Building on this, I pursued a Master of Fine Arts in Electro-Acoustic Music Composition at the 
                                California Institute of the Arts. There, I studied under influential composers Morton Subotnick, 
                                Lucky Mosko, and Mel Powell, refining my skills in both composition and electronic music. I also 
                                explored improvisational techniques with Roscoe Mitchell, allowing me to expand my creative 
                                horizons and incorporate unconventional methods into my compositions. This blend of traditional 
                                performance training and cutting-edge composition techniques provided me with the tools to create 
                                music that crosses boundaries, blending acoustic and electronic elements in innovative ways.
                                <br/><br/>
                                This strong educational background in both performing and composing laid the groundwork for my 
                                later achievements in both music and software development, fostering a unique ability to merge 
                                technical and artistic disciplines throughout my career.
                            </span>)}
                            <button onClick={toggleEducation}>
                                {isShowingMoreEducation ? 'less' : 'more'}
                            </button>
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Main;
