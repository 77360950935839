import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Main from './pages/Main';
import Showcase from './pages/Showcase';

function AppContent() {
    const [categories, setCategories] = useState([]);
    const [showcases, setShowcases] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();  // Get the current path

    useEffect(() => {
        fetch('/site4.json')
            .then(response => response.json())
            .then(data => {
                if (!data || !data.site || !data.site.categories) {
                    throw new Error('Invalid JSON structure or empty data');
                }
                setCategories(data.site.categories);
                setShowcases(data.site.showcases);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error during fetch:', error);
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    
    return (
        <>
            {/* Pass the current path to the Navbar */}
            <Navbar categories={categories} currentPath={location.pathname} /> 
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/showcase/:category?" element={<Showcase showcases={showcases} />} />
            </Routes>
        </>
    );
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;
