import React, { useEffect, useState } from 'react';
import '../../style/players/instagramPlayer.css';

const InstagramPlayer = ({ currentShowcase }) => {
  return (
    <iframe
      id="instagram-iframe"
      src={currentShowcase.iframePath}
      frameBorder="0"
      allow="autoplay; encrypted-media"
      scrolling="no"
      allowFullScreen
      title={currentShowcase.videoTitle}
    ></iframe>
  )
}

export default InstagramPlayer