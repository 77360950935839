import React, { useEffect, useState } from 'react';
import '../../style/players/youtubePlayer.css';

const YoutubePlayer = ({ currentShowcase }) => {

  return (
    <div className="youtube-video-container">
      <iframe
        id="youtube-iframe"
        src={`https://www.youtube.com/embed/${currentShowcase.youtubeId}?rel=0&autoplay=0`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title={currentShowcase.videoTitle}
      ></iframe>
    </div>

  )
}

export default YoutubePlayer;
