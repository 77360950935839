import React, { useEffect, useState } from 'react';
import YoutubePlayer from './players/YoutubePlayer';
import BandcampPlayer from './players/BandcampPlayer';
import InstagramPlayer from './players/InstagramPlayer';
import VideoPlayer from './players/VideoPlayer';

const ShowcaseArea = ({ showcase }) => {
    const [isShowcaseFadedIn, setIsShowcaseFadedIn] = useState(false); // State to control fade-in
    const [currentShowcase, setCurrentShowcase] = useState(showcase); // State to store the current showcase

    useEffect(() => {
        // When the showcase changes, fade out the current content
        setIsShowcaseFadedIn(false);

        // Set a timeout to wait for the fade-out to complete before updating the showcase
        const fadeOutTimeout = setTimeout(() => {
            setCurrentShowcase(showcase); // Update to the new showcase after fading out

            // Fade in the new content
            setTimeout(() => {
                setIsShowcaseFadedIn(true);
            }, 100); // Slight delay to allow for fade-in
        }, 500); // Wait for the fade-out to complete (adjust timing to match the transition duration)

        return () => {
            clearTimeout(fadeOutTimeout);
        };
    }, [showcase]);

    const showcaseContainerStyle = {
        transition: 'opacity 500ms ease-in-out',
        opacity: isShowcaseFadedIn ? '1' : '0', // Set opacity based on fade state
    };

    const renderPlayer = () => {
        if (currentShowcase.youtubeId) {
            return <YoutubePlayer currentShowcase={currentShowcase} />;
        }
        if (currentShowcase.bandcampAlbum) {
            return <BandcampPlayer currentShowcase={currentShowcase} />;
        }
        if (currentShowcase.iframePath) {
            return <InstagramPlayer currentShowcase={currentShowcase} />;
        }
        return <VideoPlayer currentShowcase={currentShowcase} />;
    };

    return (
        <div
            className="showcase"
            style={showcaseContainerStyle}
        >
            {currentShowcase && (
                <div className="showcase-player-area">
                    {renderPlayer()}
                    <div className="showcase-textarea">
                        <h2>{currentShowcase.videoTitle}</h2>
                        <span className="showcase-year">{currentShowcase.dateRange}</span>
                        <div className="showcase-caption">{currentShowcase.captionText}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowcaseArea;
