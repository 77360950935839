import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../style/navbar.css';

const Navbar = ({ categories = [], currentPath }) => {

    const [isDropdownDown, setDropdownDown] = useState(false);
    const [iconOpacity, setIconOpacity] = useState(0.1);
    const iconLightRef = useRef(null); // Reference to the icon


    const location = useLocation();
    const isHomeSelected = location.pathname === '/' ? 'selected' : ''
    const isShowcaseSelected = location.pathname.startsWith('/showcase') ? 'selected' : '';
    const showDropdown = () => {
        setDropdownDown(true)
    }
    const parsedPath = currentPath.split("/")[2] 
    // || location.pathname.startsWith('/showcase') ? 'all' : '';

    const hideDropdown = () => {
        if (isHomeSelected) {
            setDropdownDown(false);
        }
    };

    useEffect(() => {
        if (location.pathname === '/') {
            setDropdownDown(false);  // Close the dropdown when home is selected
        } else {
            setDropdownDown(true);  // open the dropdown when its not
        }
    }, [location.pathname]);

    function getCategoryRoute(category) {
        if (category === "all" || category === "") {
            return 'all';
        }
        return category.toLowerCase().replace(/ /g, '-');
    }

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (iconLightRef.current) {
                const rect = iconLightRef.current.getBoundingClientRect();
                const iconCenterX = rect.left + rect.width / 2;
                const iconCenterY = rect.top + rect.height / 2;

                const distance = Math.sqrt(
                    Math.pow(event.clientX - iconCenterX, 2) +
                    Math.pow(event.clientY - iconCenterY, 2)
                );

                const maxDistance = 300; // Define the maximum distance
                const opacity = Math.max(1 - distance / maxDistance, 0.1);
                setIconOpacity(opacity); // Update the icon's opacity
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove); // Cleanup event listener
        };
    }, []);

    return (
        <nav>
            <img
                className="icon"
                src="/images/thinknoiiseIcon.png"
                style={{ opacity: iconOpacity }} // Set the dynamic opacity
                alt="Thinknoise Icon"
            />
            <ul className="navbar">
                <li key='home'>
                    <Link 
                        to="/" 
                        className={isHomeSelected}
                        ref={iconLightRef} // Attach the ref to the icon
                    >
                        Wiley Evans
                    </Link>
                </li>
                {/* need to add hover effect */}
                <li key='showcase'
                    onMouseEnter={showDropdown}
                    onMouseLeave={hideDropdown}
                >
                    <Link
                        to="/showcase/software"
                        className={isShowcaseSelected}
                    >
                        Showcase
                    </Link>
                </li>
            </ul>

            <ul 
                className={`dropdown-content ${isDropdownDown ? 'down' : ''}`}
                onMouseEnter={showDropdown}  // Keep dropdown visible on hover
                onMouseLeave={hideDropdown}  // Hide dropdown when leaving both
            >
                {categories.map((category, index) => {
                    return (
                        <li key={index}>
                            <Link
                                className={parsedPath === category.toLowerCase() ? 'selected' : ''}
                                to={`/showcase/${getCategoryRoute(category)}`}
                            >
                                {category}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Navbar;
